import { Route, Routes } from 'react-router-dom';
import Login from '../view/Login/Login';
import ControlPoints from '../view/ControlPoints/ControlPoints';
import Home from '../view/Home/Home.';
import WelcomeUser from '../view/WelcomeUser/WelcomeUser';
import Task from '../view/Task/Task';
import TaskDetailsOnce from '../view/Task/TaskDetailsOnce';
import { ProtectedRoute } from '../ProtectedRoute';

const RoutesComponent = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<Home />} />
    <Route
      path="*"
      element={
        <ProtectedRoute>
          <Routes>
            <Route path="/control-points" element={<ControlPoints />} />
            <Route path="/welcome-user" element={<WelcomeUser />} />
            <Route path="/task" element={<Task />} />
            <Route path="/task-details/:taskId" element={<TaskDetailsOnce />} />
          </Routes>
        </ProtectedRoute>
      }
    />
  </Routes>
);

export default RoutesComponent;
