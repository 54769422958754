import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import Header from './view/Partials/Header';
import ToastProvider from './providers/toast.provider';
import { AuthProvider } from './hooks/useAuth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RoutesComponent from './utils/routes';

function App() {
  const queryClient = new QueryClient();

  document.title = 'ПРОметрика';

  return (
    <ToastProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Header />
            <RoutesComponent />
            {/* <Footer number={10} /> */}
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
