import React from 'react';
import Pagination from 'react-js-pagination';
import Select from './Select';

const ProjectPagination = (props: any) => {
  const {
    currentPage,
    totalItems,
    pageChange,
    itemsPerPage,
    limitItemsOptions,
    handleLimitSelect,
    limitFilter,
  } = props;
  return (
    <div className="flex items-end ml-auto">
      <Pagination
        activePage={currentPage || 1}
        totalItemsCount={totalItems || 0}
        onChange={(pageNumber: number) => pageChange(pageNumber)}
        pageRangeDisplayed={10}
        itemsCountPerPage={+itemsPerPage}
        innerClass="flex flex-wrap items-center justify-center gap-[2px] "
        linkClass="flex items-center justify-center w-[32px] h-[32px] text-[14px] px-[12px] rounded-[4px] py-[5px] hover:bg-[#4E67EA] hover:text-white transition-all ease-linear"
        activeLinkClass="w-[32px] h-[32px] text-white text-[14px] px-[12px] rounded-[4px] py-[5px] bg-[#4E67EA] "
        linkClassPrev="flex w-[35px]  items-center justify-center rounded hover:bg-[#4E67EA] "
        linkClassNext="flex w-[35px] items-center justify-center rounded hover:bg-[#4E67EA] "
        prevPageText={
          <svg
            width={18}
            height={18}
            className="fill-gray-400 rotate-90 cursor-pointer hover:fill-black transition-all ease-linear"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
          </svg>
        }
        nextPageText={
          <svg
            width={14}
            height={14}
            className="fill-gray-400 rotate-[-90deg] cursor-pointer hover:fill-black transition-all ease-linear"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
          </svg>
        }
        hideDisabled={true}
      />
      <div className="bg-blue-200 hover:bg-blue-300 px-[2px] py-[1px] rounded-[4px] text-center flex justify-center items-center cursor-pointer mt-2">
        <Select
          options={limitItemsOptions}
          onChange={handleLimitSelect}
          value={limitFilter}
        />
      </div>
    </div>
  );
};

export default ProjectPagination;
