import React from 'react';
import { TASK_STATUSES } from '../view/Task/Task';

interface TabProps {
  status: string;
  setStatus: (status: string) => void;
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

const Tab: React.FC<TabProps> = ({
  status,
  setStatus,
  searchParams,
  setSearchParams,
}) => {
  return (
    <div className="px-[10px] p-[6px] flex justify-center items-center bg-[#F8F8F8] rounded-[6px]">
      <button
        onClick={() => {
          setStatus(TASK_STATUSES.WAITING);
          searchParams.set('status', TASK_STATUSES.WAITING);
          setSearchParams(searchParams);
        }}
        className={
          'text-[14px] font-nunito cursor-pointer rounded-[2px] px-[14px] py-[6px] ' +
          (status === TASK_STATUSES.WAITING
            ? 'bg-blue-100 text-black'
            : 'opacity-60')
        }
      >
        В ожидании
      </button>
      <button
        onClick={() => {
          setStatus(TASK_STATUSES.IN_PROGRESS);
          searchParams.set('status', TASK_STATUSES.IN_PROGRESS);
          setSearchParams(searchParams);
        }}
        className={
          'text-[14px] font-nunito cursor-pointer rounded-[2px] px-[14px] py-[6px] ' +
          (status === TASK_STATUSES.IN_PROGRESS
            ? 'bg-blue-100 text-black'
            : 'opacity-60')
        }
      >
        Незавершённые
      </button>
      <button
        onClick={() => {
          setStatus(TASK_STATUSES.DONE);
          searchParams.set('status', TASK_STATUSES.DONE);
          setSearchParams(searchParams);
        }}
        className={
          'text-[14px] font-nunito cursor-pointer rounded-[2px] px-[14px] py-[6px] ' +
          (status === TASK_STATUSES.DONE
            ? 'bg-blue-100 text-black'
            : 'opacity-60')
        }
      >
        Выполненные
      </button>
    </div>
  );
};

export default Tab;
