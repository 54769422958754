import {
  MutationKey,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import axios from 'axios';
import { getLocaleStorage } from '../utils/functions';
import { MAIN_URL } from '../api/url';

export const token = getLocaleStorage('prometrica_token');

const tokenWithoutQuotes = token?.replace(/['"]+/g, '');

// export const refreshToken = getLocaleStorage('prometrice_refresh_token');

export const api = axios.create({
  baseURL: MAIN_URL,
  headers: {
    Authorization: `Bearer ${tokenWithoutQuotes}`,
  },
});

//==== главные фунцию для запросов и мутаций =========
export const fetchData = async <T = unknown>(
  endpoint: string,
  queryParams: Record<string, any> = {}
): Promise<T> => {
  let queryString = '';
  for (const [key, value] of Object.entries(queryParams)) {
    if (Array.isArray(value)) {
      // Для каждого значения массива добавляем отдельный параметр с тем же ключом
      value.forEach((val) => {
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(val)}&`;
      });
    } else {
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }
  }
  queryString = queryString.slice(0, -1); // Удалить последний символ '&'
  const url = `/${endpoint}${queryString ? `?${queryString}` : ''}`;

  const response = await api.get<T>(url);

  return response.data;
};

// Хук для использования запроса данных
export const useCustomQuery = <TData = unknown, TError = unknown>(
  endpoint: string,
  queryParams?: Record<string, any>,
  userOptions?: Omit<
    UseQueryOptions<TData, TError, TData, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<TData, TError, TData, QueryKey>({
    queryKey: [endpoint, queryParams] as QueryKey,
    queryFn: () => fetchData<TData>(endpoint, queryParams),
    refetchOnMount: 'always',
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: false,
    retry: false,
    ...userOptions,
  });
};

async function mutateData<TData, TVariables = Record<string, unknown>>(
  endpoint: string,
  variables: TVariables = {} as TVariables,
  method: 'POST' | 'PATCH' | 'DELETE' | 'PUT'
): Promise<TData> {
  const url = `/${endpoint}`;
  const response = await api({
    method: method,
    url: url,
    data: variables,
  });

  if (response.status !== 200) {
    throw new Error('Network response was not ok');
  }

  return response.data;
}

// Хук мутаций
export const useCustomMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>(
  endpoint: string,
  method: 'POST' | 'PATCH' | 'DELETE' | 'PUT',
  userOptions?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationFn'
  >
) => {
  const queryClient = useQueryClient();

  return useMutation<TData, TError, TVariables, TContext>({
    mutationKey: [endpoint] as MutationKey,
    mutationFn: (variables: TVariables = {} as TVariables) =>
      mutateData<TData, TVariables>(endpoint, variables, method),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [endpoint] });
    },
    ...userOptions,
  });
};

//====   Axios интерсептор для обработки запросов, чтобы после логина сразу передать токен в апишки, чтобы апишки не срабатывали ошибкой   =========

// // Axios интерсептор для обработки ответов
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//
//     // Проверяем, не является ли запрос попыткой логина
//     if (originalRequest.url.includes('/auth/login')) {
//       return Promise.reject(error);
//     }
//
//     // Проверка на ошибки 401 и 403
//     if (
//       error.response &&
//       (error.response.status === 401 || error.response.status === 403)
//     ) {
//       removeLocaleStorage('prometrica_token');
//       // Router.push('/auth/signin');
//     }
//
//     return Promise.reject(error);
//   }
// );

api.interceptors.request.use(
  (config) => {
    // Инициализация headers, если они не определены
    if (!config.headers) {
      config.headers = {};
    }

    if (tokenWithoutQuotes) {
      config.headers.Authorization = `Bearer ${tokenWithoutQuotes}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
