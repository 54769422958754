import React, { useEffect, useState } from 'react';

const useIntersectionObserver = (
  ref: React.RefObject<Element>,
  options?: IntersectionObserverInit
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      const threshold = 1000;
      const isNearBottom =
        entry.target.scrollHeight - entry.target.scrollTop <=
        entry.target.clientHeight + threshold;
      if (entry.isIntersecting && isNearBottom) {
        setIsIntersecting(true);
      } else {
        setIsIntersecting(false);
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
};

export default useIntersectionObserver;
