import axios from 'axios';
import { MAIN_URL } from './url';

export const requestGet = async (url, options = {}) => {
  try {
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://prometrica.org:443';
    // axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
    // axios.defaults.headers.post['Access-Control-Allow-Credentials'] = 'true';
    // axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Host, Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-XSRF-TOKEN, Origin, Access-Control-Request-Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin, access-control-allow-origin, Access-Control-Allow-Credentials, access-control-allow-credentials, Access-Control-Allow-Headers, access-control-allow-headers, Access-Control-Allow-Methods, access-control-allow-methods';

    const response = await axios.get(MAIN_URL + url, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Headers':
          'Host, Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,X-XSRF-TOKEN, Origin, Access-Control-Request-Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin, access-control-allow-origin, Access-Control-Allow-Credentials, access-control-allow-credentials, Access-Control-Allow-Headers, access-control-allow-headers, Access-Control-Allow-Methods, access-control-allow-methods',
      },
    });
    return response.data;
  } catch (error) {
    return console.log(error);
  }
};

export const requestGetFetch = async (url, params = {}, accessToken) => {
  try {
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    const response = fetch(
      `${MAIN_URL}${url}?${new URLSearchParams(params).toString()}`,
      {
        method: 'GET',
        headers: headers,
        mode: 'cors',
      }
    );
    const _response = (await response).json();
    return _response;
  } catch (error) {
    console.error(error);
  }
};

export const requestGetFetchByOptions = async (
  url,
  params = {},
  newOptionsParsedInt,
  accessToken
) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };

    let stringUrlOptions = ''; // '&options=';
    for (let i = 0; i < newOptionsParsedInt?.length; i++) {
      if (i > 0) {
        stringUrlOptions =
          stringUrlOptions +
          '&options={"id":' +
          newOptionsParsedInt[i].id +
          ',"value":"' +
          newOptionsParsedInt[i].value +
          '"}';
      } else {
        stringUrlOptions =
          stringUrlOptions +
          '&options={"id":' +
          newOptionsParsedInt[i].id +
          ',"value":"' +
          newOptionsParsedInt[i].value +
          '"}';
      }
    }

    const response = fetch(
      `${MAIN_URL}${url}?${new URLSearchParams(params).toString()}`,
      {
        method: 'POST',
        headers: headers,
        mode: 'cors',
        body: JSON.stringify(newOptionsParsedInt),
      }
    );
    const _response = (await response).json();
    return _response;
  } catch (error) {
    console.error(error);
  }
};

export const requestGetFetchByOptionsOld = async (
  url,
  params = {},
  newOptionsParsedInt
) => {
  try {
    const headers = {
      Accept: 'application/json',
      // Authorization: `Bearer ${accessToken}`,
    };

    let stringUrlOptions = ''; // '&options=';
    for (let i = 0; i < newOptionsParsedInt?.length; i++) {
      if (i > 0) {
        stringUrlOptions =
          stringUrlOptions +
          '&options={"id":' +
          newOptionsParsedInt[i].id +
          ',"value":"' +
          newOptionsParsedInt[i].value +
          '"}';
      } else {
        stringUrlOptions =
          stringUrlOptions +
          '&options={"id":' +
          newOptionsParsedInt[i].id +
          ',"value":"' +
          newOptionsParsedInt[i].value +
          '"}';
      }
    }

    const response = fetch(
      `${MAIN_URL
      }${url}?${new URLSearchParams(params).toString() + stringUrlOptions}`,
      {
        method: 'GET',
        headers: headers,
        mode: 'cors',
      }
    );
    const _response = (await response).json();
    return _response;
  } catch (error) {
    console.error(error);
  }
};

export const requestPatchFetch = async (url, params = {}) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${accessToken}`,
    };
    const response = fetch(`${MAIN_URL}${url}`, {
      method: 'PATCH',
      headers: headers,
      mode: 'cors',
      body: JSON.stringify(params),
    });
    const _response = (await response).json();
    return _response;
  } catch (error) {
    console.error(error);
  }
};

export const requestPostReadyItems = async (
  url,
  data = {},
  config = {},
  accessToken
) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    const response = fetch(`${MAIN_URL}${url}`, {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      body: JSON.stringify(data),
    });
    const _response = (await response).json();
    return _response;
  } catch (error) {
    console.error(error);
  }
};

export const requestPost = async (url, data = {}, config = {}, accessToken) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    const response = fetch(`${MAIN_URL}${url}`, {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      body: JSON.stringify(data),
    });

    const _response = await (await response).text();
    return (_response ? JSON.parse(_response) : {});
  } catch (error) {
    console.error(error);
  }
};

export const requestDelete = async (url, data = {}, config = {}) => {
  try {
    const response = await axios.delete(MAIN_URL + url, data, config);
    return response.data;
  } catch (error) {
    return console.log(error);
  }
};
