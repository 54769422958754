import React from 'react';

interface ImageComponentProps {
  src: string | null;
  alt: string;
  className?: string;
}

const Image: React.FC<ImageComponentProps> = ({
  src,
  alt,
  className = 'w-[198px] h-[154px]',
}) => {
  return (
    <>
      {src ? (
        <img
          alt={alt}
          className={`bg-no-repeat rounded-[4px] ${className}`}
          src={src}
        />
      ) : (
        <div className={`bg-gray-100 rounded-[4px] ${className}`} />
      )}
    </>
  );
};

export default Image;
