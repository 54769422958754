import { CONTROL_POINTS } from '../../api/url';
import ControlPoint from '../Home/ControlPoint/ControlPoint';
import { useAuth } from '../../hooks/useAuth';
import { loaderSvg } from '../../utils/functions';
import { useCustomQuery } from '../../hooks/useFetch';

export default function ControlPoints() {
  const { setControlPointId, controlPointId }: any = useAuth();

  const { data: controlPoints, isLoading: isControlPointsLoading } =
    useCustomQuery<any>(CONTROL_POINTS, undefined);

  const controlPointsData = controlPoints?.data;

  return (
    <div className="relative top-[59px] w-full h-auto min-h-full flex flex-col gap-[56px] m-auto justify-start items-center py-[14px] bg-[#F8F8F8]">
      <div className="flex flex-col gap-[32px]">
        <h1 className="font-nunito text-[24px] leading-[52px] font-semibold text-center">
          Выберите контрольную точку
        </h1>
        <div className="flex w-full justify-center items-center">
          {isControlPointsLoading ? (
            loaderSvg()
          ) : (
            <div className="flex flex-wrap max-w-[900px] gap-[35px] justify-center overflow-y-auto">
              {controlPointsData?.length > 0 &&
                controlPointsData.map((value: any) => {
                  return (
                    <ControlPoint
                      id={value.id}
                      description={value.description}
                      image={value.image}
                      key={value?.id}
                      activeMaterialId={value.active_material_id}
                      activeTaskId={value.active_task_id}
                      setControlPointId={setControlPointId}
                      controlPointId={controlPointId}
                      hasMirror={!!value.mirror_id}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
