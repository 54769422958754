import React from 'react';

interface PrimaryButtonProps {
  label: string;
  isSelected: boolean;
  onClick: (e: any) => void;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  label,
  isSelected,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={
      'cursor-pointer hover:bg-[#4E67EA] transition-all ease-linear hover:text-white rounded-[4px] px-[12px] py-[8px] flex grow text-center justify-center items-center ' +
      (isSelected ? 'bg-[#4E67EA] text-white' : 'bg-[#F8F8F8] text-[#D9DADC]')
    }
  >
    {label}
  </div>
);

export default PrimaryButton;
