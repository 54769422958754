import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestGetFetch, requestPost } from '../../api/request';
import { SHIFT, USERS } from '../../api/url';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import SelectNew from '../../components/SelectNew';
import PrimaryButton from '../../components/buttons/PrimaryButton';

export const shifts: any = {
  first: 'Ⅰ смена',
  second: 'Ⅱ смена'
};

export default function Login() {
  const {
    accessToken,
    refreshToken,
    controlPointId,
    userId,
    setUserId,
    channelWork,
    setChannelWork,
  }: any = useAuth();

  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any>();
  const [cChannelWork, sSetChannelWork] = useState<string>('');
  const [shiftId, setShiftId] = useState<any>();

  const getData = async () => {
    const response = await requestGetFetch(
      USERS,
      {
        control_point_id: controlPointId,
      },
      accessToken
    );
    if (response?.data?.length > 0) {
      setData(response?.data);
      setUserId(response?.data[0]?.id);
    }

    if (response?.error?.length > 0) {
      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [accessToken, controlPointId]);

  const startShift = async () => {
    const response = await requestPost(
      SHIFT,
      {
        user_id: userId,
        shift_number: channelWork,
      },
      {},
      accessToken
    );

    if (response?.error?.length > 0) {
      toast.warning(response?.error[0]);

      if (
        response?.error[0] == 'detail: Given token not valid for any token type'
      ) {
        refreshToken();
      }
    }

    navigate(`/welcome-user`);
  };

  return (
    <div className="w-full bg-[#EEEEEE] flex justify-center items-center py-[195px] h-full">
      <div className="flex flex-col gap-[32px] w-[536px] bg-white p-[32px] rounded-[8px]">
        <div
          data-testid="login-container"
          className="text-[28px] font-nunito font-semibold text-center"
        >
          Выберите пользователя
        </div>
        <div className="flex flex-col gap-[24px]  w-full">
          <SelectNew
            options={data}
            onChange={(value) => {
              setSelectedData(value);
              setUserId(value?.id);
            }}
            defaultValue={data?.length > 0 && data[0]}
            zIndex={10}
          />

          <div className="w-full flex gap-[10px] text-[#C0C1C3] text-[16px] font-nunito ">
            {selectedData?.shift?.length > 0
              ? selectedData?.shift?.map((value: any) => (
                  <PrimaryButton
                    label={shifts[value?.shift_number]}
                    isSelected={cChannelWork === value?.shift_number}
                    onClick={() => {
                      sSetChannelWork(value?.shift_number);
                      setChannelWork(value?.shift_number);
                      setShiftId(value?.id);
                    }}
                  />
                ))
              : Object.keys(shifts).map((shiftKey) => (
                  <PrimaryButton
                    label={shifts[shiftKey]}
                    isSelected={cChannelWork === shiftKey}
                    onClick={() => {
                      sSetChannelWork(shiftKey);
                      setChannelWork(shiftKey);
                    }}
                  />
                ))}
          </div>

          {!!cChannelWork ? (
            <PrimaryButton
              onClick={() => startShift()}
              label="Продолжить"
              isSelected={!!selectedData}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
