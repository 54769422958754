import ReactDOM from 'react-dom';
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '../icons/SearchIcon';
import VirtualKeyboard from './VirtualKeyboard';

const Input: React.FC<{ value: string; onChange: any }> = ({
  value,
  onChange,
}) => {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const keyboardRef = useRef<HTMLDivElement>(null);

  const handleFocus = (e: any) => {
    e.preventDefault();
    setShowKeyboard(true);
  };

  const handleCloseKeyboard = () => {
    setShowKeyboard(false);
  };

  const handleKeyPress = (key: string) => {
    if (key === 'delete') {
      onChange({ target: { value: value.slice(0, -1) } });
    } else {
      onChange({ target: { value: value + key } });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key >= '0' && e.key <= '9') || e.key === 'Backspace') {
      let newValue;
      if (e.key === 'Backspace') {
        newValue = value.slice(0, -1);
      } else {
        newValue = value + e.key;
      }
      onChange({ target: { value: newValue } });
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (showKeyboard && inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      const keyboardRoot = document.getElementById('keyboard-root');
      if (keyboardRoot) {
        keyboardRoot.style.position = 'absolute';
        keyboardRoot.style.left = `${rect.left}px`;
        keyboardRoot.style.top = `${rect.bottom + window.scrollY + 10}px`;
      }
    }
  }, [showKeyboard]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        keyboardRef.current &&
        !keyboardRef.current.contains(event.target)
      ) {
        setShowKeyboard(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="px-[10px] p-[6px] flex gap-[5px] justify-center items-center border rounded-[6px]">
      <SearchIcon />
      <input
        ref={inputRef}
        type="number"
        className="text-[14px] font-nunito outline-none"
        placeholder="Поиск по задачам"
        onChange={onChange}
        value={value}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        readOnly={true}
      />
      {showKeyboard &&
        ReactDOM.createPortal(
          <div ref={keyboardRef}>
            {' '}
            <VirtualKeyboard
              onKeyPress={handleKeyPress}
              onClose={handleCloseKeyboard}
            />
          </div>,
          document.getElementById('keyboard-root')!
        )}
    </div>
  );
};

export default Input;
