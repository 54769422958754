import { Navigate } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";

export const ProtectedRoute = ({ children }: any) => {
  const { accessToken }: any = useAuth();

  if (!accessToken) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  return children;
};
