import React from 'react';

interface IButtonProps {
  text: string;
  onClick?: () => void;
  color?: 'primary' | 'secondary' | 'black' | 'outline';
  height?: string;
  noRadius?: boolean;
  disabled?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  className?: string;
}

const Button: React.FC<IButtonProps> = ({
  text,
  onClick,
  color = 'primary',
  height = '86px',
  noRadius = false,
  disabled = false,
  iconLeft,
  iconRight,
  className,
}) => {
  const styles = {
    primary: {
      backgroundColor: disabled ? 'bg-[#F2F3F5]' : 'bg-[#4E67EA]',
      textColor: disabled ? 'text-[#C0C1C3]' : 'text-white',
      hoverBgColor: disabled ? '' : 'hover:bg-[#768aed]',
      activeBgColor: disabled ? '' : 'active:bg-[#5870ed]',
    },
    secondary: {
      backgroundColor: disabled ? 'bg-[#F2F3F5]' : 'bg-[#E6E6E6]',
      textColor: disabled ? 'text-[#C0C1C3]' : 'text-[#00000099]',
      hoverBgColor: '',
      activeBgColor: '',
    },
    black: {
      backgroundColor: disabled ? 'bg-[#F2F3F5]' : 'bg-[#000000]',
      textColor: disabled ? 'text-[#C0C1C3]' : 'text-white',
      hoverBgColor: '',
      activeBgColor: '',
    },
    outline: {
      backgroundColor: 'bg-transparent',
      textColor: 'text-black]',
      hoverBgColor: 'hover:bg-[#4052ad]',
      hoverTextColor: 'hover:text-white',
      borderColor: 'border-[#4E67EA]',
      hoverIconColor: 'hover:[&>span>svg]:fill-[#FFFFFF]',
    },
  }[color];

  const borderRadiusClass = noRadius ? '' : 'rounded-md';

  return (
    <button
      onClick={!disabled ? onClick : undefined}
      style={{ minHeight: height, lineHeight: '22px' }}
      className={`select-none w-full flex flex-row justify-center items-center cursor-pointer text-[24px] text-nowrap font-nunito border p-[24px] transition-all ease-linear ${styles.borderColor} ${styles.backgroundColor} ${styles.activeBgColor} ${styles.hoverBgColor} ${styles.textColor} ${styles.hoverTextColor} ${styles.hoverIconColor} ${borderRadiusClass} ${className}`}
      disabled={disabled}
    >
      {iconLeft && <span className="mr-2">{iconLeft}</span>}
      {text}
      {iconRight && <span className="ml-2">{iconRight}</span>}
    </button>
  );
};

export default Button;
