export default function PlayIcon({ w, h }: any) {
  return (
    <svg
      width="122"
      height="122"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="122" height="122" fill="#DEDEDE" />
      <path
        d="M72.3333 55.2132L55 45.2132C53.9856 44.6276 52.8347 44.3195 51.6634 44.3201C50.492 44.3206 49.3415 44.6298 48.3276 45.2165C47.3138 45.8032 46.4724 46.6467 45.8882 47.662C45.3041 48.6773 44.9977 49.8285 45 50.9999V70.9999C44.9977 72.1713 45.3041 73.3226 45.8882 74.3379C46.4724 75.3532 47.3138 76.1966 48.3276 76.7833C49.3415 77.37 50.492 77.6792 51.6634 77.6797C52.8347 77.6803 53.9856 77.3723 55 76.7866L72.3333 66.7866C73.3462 66.1978 74.1868 65.3535 74.7711 64.338C75.3554 63.3225 75.6629 62.1715 75.6629 60.9999C75.6629 59.8283 75.3554 58.6773 74.7711 57.6618C74.1868 56.6463 73.3462 55.802 72.3333 55.2132Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}
