import React from 'react';

const Checkbox: React.FC<{
  checked: boolean;
  setCheckbox: (checked: boolean) => void;
}> = ({ checked, setCheckbox }) => {
  return (
    <div
      className="flex items-center gap-2.5 cursor-pointer"
      onClick={() => setCheckbox(!checked)}
    >
      <input
        type="checkbox"
        checked={checked}
        className="form-checkbox h-6 w-6 border border-gray-200 rounded text-blue-600 focus:ring-blue-500"
      />
      <span className="text-xl">Весь рулон</span>
    </div>
  );
};

export default Checkbox;
