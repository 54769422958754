import React from 'react';
import Input from '../../components/Input';
import { CloseIcon } from '../../icons';

interface TaskFilterContainerProps {
  searchText: string;
  setSearchText: (text: string) => void;
  options: any[];
  setFilterOptions: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  parsedSelectedOptions: any[];
  removeOptionById: (id: string) => void;
  setParsedSelectedOptions: (options: any[]) => void;
  setSearchParams: (params: any) => void;
  handleSearchText: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TaskFilterContainer: React.FC<TaskFilterContainerProps> = ({
  searchText,
  setSearchText,
  options,
  setFilterOptions,
  parsedSelectedOptions,
  removeOptionById,
  setParsedSelectedOptions,
  setSearchParams,
  handleSearchText,
}) => {
  return (
    <div className="bg-white w-full flex gap-[8px] items-center p-[16px] text-[20px] font-nunito font-medium">
      <Input onChange={handleSearchText} value={searchText} />

      <div className="px-[10px] p-[6px] flex gap-[5px] justify-center items-center">
        {options?.length > 0 &&
          options?.map((value: any, index: number) => {
            if (value?.task_to_options?.length !== 0) {
              return (
                <select
                  onChange={(e) => setFilterOptions(e)}
                  key={index}
                  className="text-[14px] px-[10px] p-[6px] flex gap-[5px] justify-center items-center border rounded-[6px] font-nunito outline-none text-gray-500"
                >
                  {value?.task_to_options?.length > 0 ? (
                    <option defaultValue={value?.name}>{value?.name}</option>
                  ) : null}
                  {value?.task_to_options?.length > 0 &&
                    value?.task_to_options?.map((v: any, i: number) => {
                      return (
                        <option
                          key={i}
                          data-option-id={value?.id}
                          id={v?.id}
                          value={v?.value}
                        >
                          {v?.value}
                        </option>
                      );
                    })}
                </select>
              );
            }
          })}
      </div>

      <div className="flex justify-center items-center gap-[15px] px-[10px] p-[6px]">
        {parsedSelectedOptions?.length > 0 &&
          parsedSelectedOptions?.map((value: any, index: number) => {
            return (
              <div
                className="flex justify-center items-center gap-[5px] cursor-pointer"
                key={index}
                onClick={() => removeOptionById(value?.id)}
              >
                <svg
                  width={12}
                  height={12}
                  className="fill-gray-300 hover:fill-gray-500 transition-all ease-linear"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                </svg>
                <div className="text-[14px] opacity-60 hover:opacity-100 transition-all ease-linear font-nunito cursor-pointer">
                  {value?.value}
                </div>
              </div>
            );
          })}
      </div>

      <div
        onClick={() => {
          setParsedSelectedOptions([]);
          setSearchText('');
          setSearchParams([]);
          window.location.href = `/task`;
        }}
        className="px-[10px] p-[6px] flex gap-[5px] justify-center items-center"
      >
        <CloseIcon />
        <div className="text-[14px] opacity-60 font-nunito cursor-pointer">
          Сбросить все
        </div>
      </div>
    </div>
  );
};

export default TaskFilterContainer;
