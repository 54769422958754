import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { requestGetFetch, requestPost } from '../api/request';
import { API_TOKEN_REFRESH, CONTROL_POINTS } from '../api/url';
import { getLocaleStorage, setToLocaleStorage } from '../utils/functions';

const AuthContext = createContext('0');

export const AuthProvider = ({ children }: any) => {
  const keyLocaleStorage = 'prometrica_token';
  const keyLocaleStorageRefresh = 'prometrica_token_refresh';

  const [accessToken, setAccessToken] = useLocalStorage(
    'prometrica_token',
    null
  );

  const [controlPointId, setControlPointId] = useLocalStorage(
    'control_point_id',
    null
  );

  const [userId, setUserId] = useLocalStorage('user_id', null);

  const [channelWork, setChannelWork] = useLocalStorage('channel_work', null);

  const [typeRoute, setTypeRoute] = useLocalStorage('type_route', null);

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    setAccessToken(data);
    // navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setAccessToken(null);
    setUserId(null);
    setChannelWork(null);
    setTypeRoute(null);
    setControlPointId(null);

    navigate('/', { replace: true });
  };

  const refreshToken = async () => {
    const response = await requestGetFetch(CONTROL_POINTS, {}, accessToken);
    if (response?.error?.length > 0) {
      const refreshToken = await requestPost(
        API_TOKEN_REFRESH,
        {
          refresh: getLocaleStorage(keyLocaleStorageRefresh),
        },
        accessToken
      );

      //Try to refresh Token
      if (refreshToken?.data.length > 0) {
        setToLocaleStorage(keyLocaleStorage, refreshToken?.data[0]?.access);
        login(refreshToken?.data[0]?.access);
        window.location.reload();
      }

      if (refreshToken?.error.length > 0) {
        navigate('/');
      }
    }
  };

  const value: any = useMemo(
    () => ({
      accessToken,
      login,
      logout,
      refreshToken,
      controlPointId,
      setControlPointId,
      userId,
      setUserId,
      channelWork,
      setChannelWork,
      typeRoute,
      setTypeRoute,
    }),
    [accessToken, controlPointId, userId, channelWork, typeRoute]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
