import React from 'react';
import { DefaultOption } from '../types/interfaces';

interface SelectProps {
  options: Array<DefaultOption>;
  value: string | number;

  onChange(data: string): void;
}

const Select: React.FC<SelectProps> = ({ onChange, options, value }) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <div className="flex cursor-pointer justify-center items-center text-center p-[3px]">
      <select
        className="relative cursor-pointer text-center w-full text-[14px] appearance-none rounded-[4px] bg-transparent  outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
        onChange={handleChange}
        value={value}
      >
        {options.map((item: DefaultOption) => {
          return (
            <option
              className="cursor-pointer"
              key={`${item.value}_${item.label}`}
              value={item.value}
            >
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
