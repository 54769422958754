import React from 'react';
import Button from './buttons/Button';
import { CheckBurgerIcon, PauseSmallIcon, PlaySmallIcon } from '../icons';
import { useNavigate } from 'react-router-dom';
import {
  loaderSvg,
  parseAndBoldText,
  SafeHtmlComponent,
} from '../utils/functions';
import { TASK_STATUSES } from '../view/Task/Task';

interface CustomTableProps {
  data: any[];
  options: any[];
  loader: boolean;
  status: string;
  setTypeRoute: (type: string) => void;

  setSelectedData: (data: any) => void;
  setTaskDetailsContainerShow: (show: boolean) => void;
  taskDetailsContainerShow: boolean;
  hasMirror: boolean;
  taskButtonOnClick: any;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  options,
  loader,
  status,
  setTypeRoute,

  setSelectedData,
  setTaskDetailsContainerShow,
  taskDetailsContainerShow,
  hasMirror,
  taskButtonOnClick,
}) => {
  const navigate = useNavigate();

  return (
    <table className="table-auto w-full border-collapse border border-slate-200">
      <thead className="bg-[#F2F3F5]">
        <tr className="text-[14px] font-nunito font-medium text-left [&>*]:px-[16px] [&>*]:py-[17px]">
          <th>ИД</th>
          <th>Задача</th>
          <th>Описание задачи</th>
          {options?.length > 0 &&
            options?.map((value: any, index: number) => (
              <th key={index}>{value?.name}</th>
            ))}
          <th>Действия</th>
        </tr>
      </thead>
      {loader ? (
        <tr>
          <td
            colSpan={options.length + 4}
            className="text-center p-[20px] w-full"
          >
            {loaderSvg()}
          </td>
        </tr>
      ) : data.length > 0 ? (
        data.map((value: any, index: number) => (
          <tr
            key={index}
            className={
              'text-[14px] cursor-pointer hover:bg-[#F2F3F5] font-nunito font-normal text-left [&>*]:px-[16px] [&>*]:py-[17px] [&>*]:border-collapse [&>*]:border [&>*]:border-slate-200 ' +
              (value?.is_ready_to_start === true
                ? 'bg-[#dafeed]'
                : 'bg-[#F2F3F5]')
            }
          >
            <td
              onClick={() => {
                if (status === TASK_STATUSES.WAITING) {
                  setTypeRoute('start');
                }
                if (status === TASK_STATUSES.IN_PROGRESS) {
                  setTypeRoute('continue');
                }
                navigate(`/task-details/${value?.id}`);
              }}
            >
              {value?.id}
            </td>
            <td
              onClick={() => {
                if (status === TASK_STATUSES.WAITING) {
                  setTypeRoute('start');
                }
                if (status === TASK_STATUSES.IN_PROGRESS) {
                  setTypeRoute('continue');
                }
                navigate(`/task-details/${value?.id}`);
              }}
            >
              {value?.operation?.name}
            </td>
            <td
              onClick={() => {
                if (status === TASK_STATUSES.WAITING) {
                  setTypeRoute('start');
                }
                if (status === TASK_STATUSES.IN_PROGRESS) {
                  setTypeRoute('continue');
                }
                navigate(`/task-details/${value?.id}`);
              }}
            >
              <SafeHtmlComponent
                html={parseAndBoldText(value?.operation?.description || '')}
              />
            </td>
            {options?.length > 0 &&
              options?.map((voption: any, index_option: number) => (
                <td key={index_option}>
                  {value?.task_to_options?.length > 0 &&
                    value?.task_to_options.map((v: any, i: number) => {
                      if (v?.option_id === voption?.id) {
                        return (
                          <div
                            onClick={() => {
                              if (status === TASK_STATUSES.WAITING) {
                                setTypeRoute('start');
                              }
                              if (status === TASK_STATUSES.IN_PROGRESS) {
                                setTypeRoute('continue');
                              }
                              navigate(`/task-details/${value?.id}`);
                            }}
                            key={i}
                            className="flex gap-[5px] justify-center items-center"
                          >
                            <div className="w-[6px] h-[6px] rounded-full bg-gray-300"></div>
                            <span>{v?.value}</span>
                          </div>
                        );
                      } else {
                        return <td key={i}></td>;
                      }
                    })}
                </td>
              ))}
            <td className="">
              <div className="flex flex-col gap-[8px] min-w-[182px]">
                <Button
                  text="Подробнее о задаче"
                  onClick={() => {
                    setSelectedData(value);
                    setTaskDetailsContainerShow(!taskDetailsContainerShow);
                  }}
                  iconLeft={<CheckBurgerIcon />}
                />
                {!hasMirror && (
                  <button onClick={() => taskButtonOnClick(value?.id)}>
                    {value?.execution_time != null &&
                    status !== TASK_STATUSES.DONE ? (
                      <Button
                        text="  Продолжить выполнение"
                        iconLeft={<PauseSmallIcon />}
                        color="outline"
                        className="text-base p-0"
                        height="48px"
                      />
                    ) : (
                      <Button
                        text="Начать выполнение"
                        iconLeft={<PlaySmallIcon />}
                        color="outline"
                        className="text-base p-0"
                        height="48px"
                      />
                    )}
                  </button>
                )}
              </div>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td
            colSpan={options.length + 4}
            className="text-center p-[20px] w-full"
          >
            Нет данных
          </td>
        </tr>
      )}
    </table>
  );
};

export default CustomTable;
