export default function PauseIcon({ w, h }: any) {
  return (
    <svg
      width="122"
      height="122"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="122" height="122" fill="#DEDEDE" />
      <path
        d="M51.6667 43.6653H43.6667C41.4576 43.6653 39.6667 45.4561 39.6667 47.6653V74.332C39.6667 76.5411 41.4576 78.332 43.6667 78.332H51.6667C53.8759 78.332 55.6667 76.5411 55.6667 74.332V47.6653C55.6667 45.4561 53.8759 43.6653 51.6667 43.6653Z"
        fill="black"
        fill-opacity="0.85"
      />
      <path
        d="M78.3333 43.6653H70.3333C68.1241 43.6653 66.3333 45.4561 66.3333 47.6653V74.332C66.3333 76.5411 68.1241 78.332 70.3333 78.332H78.3333C80.5424 78.332 82.3333 76.5411 82.3333 74.332V47.6653C82.3333 45.4561 80.5424 43.6653 78.3333 43.6653Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
}
