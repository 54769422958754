export default function LogoIcon({ w, h }: any) {
  return (
    <svg
      width={w}
      height={h}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 702 647"
      fill="none"
    >
      <path
        d="M140 327H301"
        stroke="black"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M192.409 71.4017C191.092 69.2941 191.731 66.5147 193.858 65.2295C227.626 44.8267 265.101 31.2771 304.139 25.3731C343.178 19.469 382.983 21.3312 421.274 30.8361C423.687 31.4349 425.119 33.9009 424.484 36.3037V36.3037C423.849 38.7065 421.387 40.1362 418.975 39.5385C381.873 30.3471 343.308 28.5517 305.485 34.2719C267.662 39.9921 231.353 53.111 198.628 72.8628C196.501 74.1471 193.726 73.5094 192.409 71.4017V71.4017Z"
        fill="#2E2F2F"
      />
      <path
        d="M347.284 384.961C330.453 384.374 314.545 377.124 303.059 364.807C291.573 352.49 285.451 336.115 286.039 319.284C286.626 302.453 293.876 286.545 306.193 275.059C318.51 263.573 334.885 257.451 351.716 258.039L350.94 280.25C340 279.868 329.357 283.848 321.351 291.313C313.345 298.779 308.632 309.119 308.25 320.06C307.868 331 311.848 341.643 319.313 349.649C326.779 357.655 337.119 362.368 348.06 362.75L347.284 384.961Z"
        fill="#2E2F2F"
      />
      <path
        d="M349 428C327.751 428 306.993 421.614 289.419 409.671C271.845 397.727 258.266 380.778 250.444 361.021L270.155 353.217C276.413 369.022 287.276 382.582 301.335 392.136C315.395 401.691 332.001 406.8 349 406.8L349 428Z"
        fill="#2E2F2F"
      />
      <path
        d="M448.607 358.254C441.728 377.156 429.612 393.71 413.675 405.983C397.739 418.256 376.5 428 357 428L356.686 406.451C372.711 404.993 387.991 399.004 400.74 389.186C413.49 379.368 423.182 366.125 428.686 351.003L448.607 358.254Z"
        fill="#2E2F2F"
      />
      <path
        d="M557.974 175.525C567.153 188.633 575.075 202.578 581.635 217.175L558.421 227.607C552.517 214.471 545.388 201.92 537.127 190.122L557.974 175.525Z"
        fill="#2E2F2F"
      />
      <path
        d="M110.348 234.456C125.473 192.9 151.165 156.003 184.892 127.401C218.619 98.7984 259.217 79.4777 302.684 71.343C346.152 63.2082 390.989 66.5402 432.777 81.0105C474.565 95.4808 511.861 120.59 540.99 153.863L521.841 170.627C495.625 140.681 462.058 118.083 424.449 105.059C386.84 92.0362 346.487 89.0374 307.366 96.3587C268.245 103.68 231.707 121.069 201.353 146.811C170.999 172.553 147.876 205.76 134.263 243.16L110.348 234.456Z"
        fill="#2E2F2F"
      />
      <path
        d="M193.403 503.907C191.486 505.963 188.261 506.079 186.243 504.121C157.954 476.663 136.43 443.006 123.37 405.809C122.439 403.156 123.897 400.277 126.567 399.399V399.399C129.238 398.521 132.11 399.977 133.043 402.629C145.565 438.198 166.151 470.388 193.188 496.674C195.203 498.634 195.32 501.851 193.403 503.907V503.907Z"
        fill="#2E2F2F"
      />
      <path
        d="M331.046 527.216C292.104 523.809 254.935 509.392 223.881 485.649C192.826 461.907 169.168 429.818 155.67 393.131L175.003 386.018C187.152 419.036 208.444 447.916 236.393 469.284C264.341 490.653 297.793 503.628 332.841 506.694L331.046 527.216Z"
        fill="#2E2F2F"
      />
      <path
        d="M543.884 370.59C546.644 371.278 548.329 374.076 547.573 376.817C534.711 423.41 505.852 464.057 465.976 491.566C426.1 519.075 377.853 531.619 329.73 527.097C326.898 526.831 324.882 524.261 325.219 521.437V521.437C325.555 518.613 328.118 516.604 330.95 516.866C376.582 521.094 422.315 509.172 460.127 483.088C497.939 457.003 525.323 418.484 537.575 374.325C538.336 371.584 541.124 369.902 543.884 370.59V370.59Z"
        fill="#2E2F2F"
      />
      <path
        d="M170.041 230.816C167.507 229.525 166.494 226.42 167.848 223.919C181.45 198.797 200.138 176.761 222.741 159.228C246.701 140.643 274.469 127.576 304.063 120.961C333.656 114.346 364.344 114.346 393.938 120.961C421.854 127.201 448.147 139.183 471.15 156.123C473.441 157.809 473.846 161.05 472.102 163.297V163.297C470.359 165.545 467.128 165.948 464.836 164.264C443.031 148.252 418.128 136.922 391.691 131.013C363.577 124.729 334.423 124.729 306.309 131.013C278.196 137.297 251.816 149.71 229.054 167.367C207.649 183.97 189.94 204.824 177.028 228.597C175.671 231.096 172.576 232.107 170.041 230.816V230.816Z"
        fill="#2E2F2F"
      />
      <path
        d="M502.86 192.896C505.039 191.068 508.293 191.348 510.066 193.572C521.391 207.775 530.787 223.414 538.011 240.081C539.142 242.69 537.862 245.695 535.225 246.76V246.76C532.588 247.826 529.593 246.549 528.458 243.94C521.643 228.274 512.807 213.567 502.173 200.195C500.403 197.969 500.681 194.724 502.86 192.896V192.896Z"
        fill="#2E2F2F"
      />
      <path
        d="M343.026 136.113C378.205 134.885 413.008 143.691 443.369 161.504C473.73 179.317 498.397 205.4 514.487 236.708L497.988 245.188C483.507 217.01 461.307 193.535 433.982 177.503C406.657 161.472 375.335 153.546 343.674 154.652L343.026 136.113Z"
        fill="#2E2F2F"
      />
      <path
        d="M164.254 311.792C166.409 270.681 182.178 231.454 209.077 200.29C235.977 169.127 272.479 147.797 312.835 139.66L316.501 157.844C280.181 165.167 247.329 184.364 223.12 212.411C198.91 240.459 184.718 275.762 182.779 312.762L164.254 311.792Z"
        fill="#2E2F2F"
      />
      <path
        d="M533.195 347.317C527.187 390.063 506.46 429.376 474.583 458.483C442.707 487.591 401.677 504.67 358.562 506.779L357.655 488.251C396.459 486.353 433.386 470.982 462.075 444.785C490.764 418.589 509.418 383.207 514.825 344.735L533.195 347.317Z"
        fill="#2E2F2F"
      />
      <path
        d="M354.892 167.094C371.095 167.66 387.107 170.772 402.342 176.318L397.058 190.836C383.347 185.845 368.935 183.044 354.353 182.535L354.892 167.094Z"
        fill="#2E2F2F"
      />
      <path
        d="M241.14 426.142C239.606 427.623 237.157 427.584 235.714 426.013C219.877 408.771 208.165 388.154 201.464 365.723C200.854 363.679 202.074 361.555 204.132 360.996V360.996C206.191 360.436 208.308 361.654 208.921 363.697C215.278 384.873 226.337 404.342 241.272 420.645C242.713 422.218 242.675 424.66 241.14 426.142V426.142Z"
        fill="#2E2F2F"
      />
      <path
        d="M284.205 461.525C275.401 457.419 267.003 452.492 259.124 446.809L268.162 434.278C275.253 439.393 282.811 443.827 290.735 447.522L284.205 461.525Z"
        fill="#2E2F2F"
      />
      <path
        d="M417.228 460.364C381.909 477.59 341.352 480.711 303.813 469.091L308.382 454.331C342.167 464.79 378.668 461.981 410.456 446.477L417.228 460.364Z"
        fill="#2E2F2F"
      />
      <path
        d="M504 321.5C504 345.882 498.23 369.917 487.161 391.642C476.091 413.366 460.038 432.162 440.313 446.493L431.232 433.994C448.984 421.096 463.432 404.179 473.394 384.627C483.357 365.076 488.55 343.444 488.55 321.5H504Z"
        fill="#2E2F2F"
      />
      <path
        d="M354 248V243H344V248H354ZM344 248V280H354V248H344Z"
        fill="black"
      />
      <path
        d="M345 412V457"
        stroke="black"
        strokeWidth="10"
        strokeLinecap="square"
      />
      <path
        d="M439 281H599"
        stroke="black"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M398 327L618 327"
        stroke="black"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M57 297L237 297"
        stroke="black"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M5 258H147"
        stroke="black"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M27 388H143"
        stroke="black"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M349 564V637"
        stroke="black"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <circle cx="349.5" cy="323.5" r="16.5" fill="black" />
      <circle cx="345.5" cy="21.5" r="21.5" fill="black" />
      <circle cx="630.5" cy="326.5" r="23.5" fill="black" />
      <circle cx="686" cy="306" r="16" fill="#2E2F2F" />
      <circle cx="607" cy="281" r="21" fill="black" />
      <circle cx="439" cy="438" r="16" fill="black" />
      <circle cx="495.5" cy="324.5" r="17.5" fill="black" />
      <circle cx="576" cy="136" r="10" fill="black" />
      <circle cx="467" cy="194" r="22" fill="black" />
      <circle cx="345" cy="499" r="9" fill="black" />
      <circle cx="237.5" cy="423.5" r="11.5" fill="black" />
      <circle cx="176.5" cy="293.5" r="21.5" fill="black" />
      <circle cx="144.5" cy="257.5" r="22.5" fill="black" />
      <circle cx="64" cy="297" r="29" fill="black" />
      <circle cx="35" cy="388" r="25" fill="black" />
      <circle cx="349" cy="624" r="23" fill="black" />
      <circle cx="343.5" cy="175.5" r="14.5" fill="black" />
      <circle cx="255" cy="220" r="10" fill="black" />
      <circle cx="475" cy="253" r="13" fill="black" />
      <circle cx="289.5" cy="201.5" r="7.5" fill="black" />
      <path
        d="M353.539 384.871C368.44 383.922 382.529 377.752 393.332 367.446C404.135 357.14 410.961 343.356 412.611 328.517C414.261 313.678 410.629 298.732 402.354 286.305C394.078 273.877 376.5 261 361.11 259L361.11 281.891C370.423 284.621 378.476 290.545 383.855 298.623C389.234 306.701 391.595 316.416 390.522 326.061C389.45 335.707 385.013 344.666 377.991 351.365C370.969 358.064 363 361 353.539 362.5V384.871Z"
        fill="#2E2F2F"
      />
      <path
        d="M243.581 310.92C246.219 285.819 257.727 262.484 276.034 245.11C294.342 227.737 318.248 217.466 343.452 216.145C368.657 214.824 393.505 222.54 413.529 237.905C433.552 253.269 447.436 275.274 452.684 299.961L431.947 304.369C427.749 284.619 416.642 267.015 400.623 254.724C384.604 242.432 364.725 236.259 344.562 237.316C324.398 238.373 305.274 246.59 290.628 260.488C275.981 274.387 266.775 293.055 264.665 313.136L243.581 310.92Z"
        fill="#2E2F2F"
      />
      <path
        d="M585.468 416.837C571.434 451.572 549.905 482.781 522.418 508.234C494.93 533.688 462.162 552.759 426.453 564.087C390.744 575.415 352.974 578.719 315.841 573.764C278.707 568.81 243.125 555.718 211.635 535.424L225.422 514.032C253.763 532.296 285.786 544.079 319.207 548.538C352.627 552.997 386.62 550.023 418.758 539.828C450.896 529.633 480.387 512.469 505.126 489.561C529.865 466.652 549.241 438.565 561.871 407.304L585.468 416.837Z"
        fill="#2E2F2F"
      />
    </svg>
  );
}
