import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import VirtualKeyboard from './VirtualKeyboard';

interface CounterProps {
  upCount: () => void;
  downCount: () => void;
  value: number;
  setValue: (value: number | ((prevValue: number) => number)) => void;
}

const Counter: React.FC<CounterProps> = ({
  upCount,
  downCount,
  value,
  setValue,
}) => {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const keyboardRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    setValue(isNaN(newValue) ? 0 : newValue);
  };

  const handleKeyPress = (key: string) => {
    switch (key) {
      case 'delete':
        setValue((prevValue: number) => {
          const newValue = prevValue.toString().slice(0, -1);
          return newValue === '' || newValue === '-'
            ? 0
            : parseInt(newValue, 10);
        });
        break;
      case '-':
        setValue((prevValue: number) => {
          const newValue = prevValue.toString().startsWith('-')
            ? prevValue.toString().slice(1)
            : '-' + prevValue.toString();
          return newValue === '-' ? 0 : parseInt(newValue, 10);
        });
        break;
      default:
        setValue((prevValue: number) => {
          const newValueStr = prevValue.toString() + key;
          return !isNaN(parseInt(newValueStr, 10))
            ? parseInt(newValueStr, 10)
            : prevValue;
        });
    }
  };

  const handleFocus = () => setShowKeyboard(true);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!showKeyboard) return;
      if (e.key === 'Backspace') {
        setValue((prevValue: number) => {
          const newValueStr = prevValue.toString().slice(0, -1);
          return newValueStr === '-' || newValueStr === ''
            ? 0
            : parseInt(newValueStr, 10);
        });
      } else if (e.key === '-') {
        setValue((prevValue: number) => {
          const newValue = prevValue.toString().startsWith('-')
            ? prevValue.toString().slice(1)
            : '-' + prevValue.toString();
          return newValue === '-' ? 0 : parseInt(newValue, 10);
        });
      } else if (!isNaN(Number(e.key)) && e.key !== ' ') {
        setValue((prevValue: number) =>
          parseInt(prevValue.toString() + e.key, 10)
        );
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [showKeyboard, setValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current?.contains(event.target as Node) ||
        keyboardRef.current?.contains(event.target as Node)
      ) {
        return;
      }
      setShowKeyboard(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (showKeyboard && inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      const keyboardRoot = document.getElementById('keyboard-root');
      if (keyboardRoot) {
        const keyboardWidth = 300;
        const screenWidth = window.innerWidth;
        const leftPosition = (screenWidth - keyboardWidth) / 3;

        keyboardRoot.style.position = 'absolute';
        keyboardRoot.style.left = `${leftPosition}px`;
        keyboardRoot.style.top = `${rect.bottom}px`;
      }
    }
  }, [showKeyboard]);

  return (
    <div className="w-fit justify-center items-center flex text-[14px] gap-[5px] border rounded-[4px]">
      <button
        onClick={downCount}
        className="select-none px-[20px] py-[20px] text-[18px] cursor-pointer hover:bg-[#4E67EA] active:bg-[#3449ad] hover:text-white text-gray-500"
      >
        -
      </button>
      <input
        ref={inputRef}
        type="text"
        className="w-[50px] text-center select-none px-[1px] py-[20px] text-[18px]"
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onTouchStart={handleFocus}
        readOnly={true}
      />
      {showKeyboard &&
        ReactDOM.createPortal(
          <div ref={keyboardRef}>
            <VirtualKeyboard
              onKeyPress={handleKeyPress}
              onClose={() => setShowKeyboard(false)}
            />
          </div>,
          document.getElementById('keyboard-root')!
        )}
      <button
        onClick={upCount}
        className="select-none px-[20px] py-[20px] text-[18px] text-gray-500 cursor-pointer hover:bg-[#4E67EA] active:bg-[#3449ad] hover:text-white"
      >
        +
      </button>
    </div>
  );
};

export default Counter;
