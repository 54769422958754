export const MAIN_URL = process.env.REACT_APP_API_URL;

/**
 * Endpoint Контрольная точка
 */
export const CONTROL_POINTS = 'control-points/';

/**
 * Операции
 */
export const OPERATIONS = 'operations/';

/**
 * 
 */
export const OPERATIONS_MATRIX = 'operations-matrix/';

export const ORDERS = 'orders/';
export const PRODUCTION_FLOW = 'production-flow/';
export const DEFECTS = 'defects/';
export const MATERIALS = 'materials/';
export const TASKS = 'tasks/';
export const PRODUCTION_FLOW_TO_NEXT = 'tasks-to-next/';
export const PRODUCTION_INSTRUCTIONS = 'instructions/';
export const SUB_TYPE = 'sub-type/';
export const TYPE = 'type/';
export const USERS = 'users/';
export const OPTIONS = 'options/';
export const DOWNTIME = 'downtime/';
export const SHIFT = 'shift/';

export const API_TOKEN = 'api/token/';
export const API_TOKEN_REFRESH = 'api/token/refresh/';
