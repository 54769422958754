import React, { ReactNode } from 'react';

interface BadgeProps {
  children: ReactNode;
}

const Badge: React.FC<BadgeProps> = ({ children }) => {
  return (
    <div className="inline-flex items-center px-3 p-[6px 12px] text-white text-[24px] bg-[#282828] rounded-[4px] h-[40px]">
      {children}
    </div>
  );
};

export default Badge;
